<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">司服伙伴报表</div>
				<div class="header-button">
					<el-button @click="onExport" :loading="buttonLoading">导出Excel</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="setting" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					:params="{ page }"
				></yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
	</div>
</template>

<script>
import { ref, defineComponent, inject } from 'vue'
import { setting as s, columns } from './config'
import { handleExport } from '@/utils/util'
import { InitTable, useFetch, useState } from '@/utils/hooks.js'
import { getStore } from '@/utils/store'
import dayjs from 'dayjs'
import { driverList, companySelect, exportDriver, maintenanceSelect } from '@/api/chart'

export default defineComponent({
	name: 'ChartDriverPartner',
	setup() {
		const setting = ref(s)
		const $message = inject('$message')
		const dataDate = setting.value.filters.find(item => item.attr === 'dataDate')
		dataDate.value = dayjs().format('YYYY-MM')
		const params = { page: { current: 1, size: 20 }, dataDate: dataDate.value }
		const [buttonLoading, setLoading] = useState(false)
		const userInfo = getStore({ name: 'userInfo' })

		const table = new InitTable(driverList, params)
		const { fetchData } = useFetch()
		const methods = {
			onChangeFilter(params) {
				params = Object.fromEntries(Object.entries(params).filter(i => i[1]))
				table.onChangeFilter(params)
			},
			async onExport() {
				setLoading(true)
				try {
					const res = await exportDriver(table.params)
					if (res) handleExport(res, '司服伙伴报表')
				} catch (err) {
					$message.error(err.message)
				} finally {
					setLoading(false)
				}
			},
		}
		async function fetchCompanyList() {
			const { err, data } = await fetchData(companySelect, true)
			if (err) $message.error(err)
			const company = setting.value.filters.find(item => item.attr === 'companyId')
			company.options = data.map(item => ({ name: item.companyName, code: item.companyId }))
		}
		async function fetchMaintainer() {
			const { companyId } = userInfo
			const list = companyId ? companyId.split(',') : []
			const { err, data } = await fetchData(maintenanceSelect, 'DriverService', list)
			if (err) $message.error(err)
			const company = setting.value.filters.find(item => item.attr === 'userId')
			company.options = data.map(item => ({ name: item.realName, code: item.id }))
		}
		fetchCompanyList()
		fetchMaintainer()
		table.fetchList()
		return {
			setting,
			columns,
			...table.res,
			buttonLoading,
			...methods,
		}
	},
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
</style>
