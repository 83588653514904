import request from '@/http'

// 导出司服伙伴
export function exportDriver(data) {
	return request({
		url: `/suf4-bi-service/driver/support/data/export`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}
// 导出维保专员报表
export function exportMaintain(data) {
	return request({
		url: '/suf4-bi-service/maintenance/man/mon/export',
		method: 'post',
		data,
		responseType: 'blob',
	})
}
// 导出入库员报表
export function exportEntry(data) {
	return request({
		url: `/suf4-bi-service/stockin/data/export`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 导出出库员报表
export function exportOutbound(data) {
	return request({
		url: `/suf4-bi-service/stockout/data/export`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 所属公司下拉筛选
export function companySelect(isPermission) {
	return request({
		url: `/suf4-user-service/company/select${isPermission ? '?isPermission=true' : ''}`,
		method: 'get',
	})
}

// 角色下拉筛选 维保专员：Mainten、司服人员：DriverService、出库员：OutStore，入库员：InStore
export function maintenanceSelect(roleCode, data = []) {
	return request({
		url: `/suf4-user-service/userInfo/listByRoleCode/${roleCode}`,
		method: 'post',
		data,
	})
}

// 司服管理报表
export function driverList(data) {
	return request({
		url: '/suf4-bi-service/driver/support/data/page',
		method: 'post',
		data,
	})
}
// 维保专员报表
export function maintainList(data) {
	return request({
		url: '/suf4-bi-service/maintenance/man/mon/page',
		method: 'post',
		data,
	})
}
// 入库员管理报表
export function entryList(data) {
	return request({
		url: '/suf4-bi-service/stockin/data/paginate',
		method: 'post',
		data,
	})
}
// 出库员管理报表
export function outboundList(data) {
	return request({
		url: '/suf4-bi-service/stockout/data/paginate',
		method: 'post',
		data,
	})
}

// 实时数据-风控预警数据统计
export function getRiskRealStatis(companyIds) {
	return request({
		url: `/suf4-risk-service/statistics/realTime?companyIds=${companyIds}`,
		method: 'get',
	})
}
// 实时数据-风控预警数据-车辆违章
export function getRiskViolateStatis(companyIds) {
	return request({
		url: `/suf4-asset-service/violate/regulation/realtime/warning?companyIds=${companyIds}`,
		method: 'get',
	})
}
// 实时数据-车辆
export function getCarStatis(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarInfo/realTimeStatistics',
		method: 'post',
		data,
	})
}
// 实时数据-租金
export function getRentStatis(companyIds) {
	return request({
		url: `/suf4-asset-service/real-time/order/data?companyIds=${companyIds}`,
		method: 'get',
	})
}
// 实时数据-事故
export function getAccidentStatis(data) {
	return request({
		url: '/suf4-asset-service/assetAccidentInfo/count',
		method: 'post',
		data,
	})
}
// 实时数据-维保
export function getMaintainStatis(data) {
	return request({
		url: '/suf4-asset-service/assetMaintenanceInfo/count',
		method: 'post',
		data,
	})
}
// 实时数据-整备收入
export function getStockInStatis(data) {
	return request({
		url: '/suf4-asset-service/pc/assetCarStockIn/realTimeStatistics',
		method: 'post',
		data,
	})
}

// 运营数据
// 运营数据-资产运营-日统计
export function getAssetList(data) {
	return request({
		url: '/suf4-bi-service/carManage/operate/data/rent/day',
		method: 'post',
		data,
	})
}
// 运营数据-资产运营-运营中车辆
export function getCarInfo(data) {
	return request({
		url: '/suf4-bi-service/carManage/operate/data/inOperationData',
		method: 'post',
		data,
	})
}
// 运营数据-资产运营-年审数据
export function getYearInfo(data) {
	return request({
		url: '/suf4-bi-service/carManage/operate/data/annualAuditData',
		method: 'post',
		data,
	})
}
// 运营数据-司机管理-日数据
export function getTodayRentList(data) {
	return request({
		url: '/suf4-bi-service/driver/operate/data/rent/day',
		method: 'post',
		data,
	})
}
// 运营数据-司机管理-周期数据
export function getRangeRentList(data) {
	return request({
		url: '/suf4-bi-service/driver/operate/data/rent/period',
		method: 'post',
		data,
	})
}
// 运营数据-合同管理-日数据
export function getTodayContractList(data) {
	return request({
		url: '/suf4-bi-service/order/operate/data/rent/day',
		method: 'post',
		data,
	})
}
// 运营数据-合同管理-周期数据
export function getRangeContractList(data) {
	return request({
		url: '/suf4-bi-service/order/operate/data/rent/period',
		method: 'post',
		data,
	})
}
// 维修保养-日数据
export function getTodayMaintainInfo(data) {
	return request({
		url: '/suf4-bi-service/maintenance/data/day/carCount',
		method: 'post',
		data,
	})
}
// 维修保养-周期数据
export function getRangeMaintainInfo(data) {
	return request({
		url: '/suf4-bi-service/maintenance/data/period/carCount',
		method: 'post',
		data,
	})
}
// 维修保养-日金额数据
export function getTodayMaintainSumList(data) {
	return request({
		url: '/suf4-bi-service/maintenance/data/day/amountCount',
		method: 'post',
		data,
	})
}
// 维修保养-周期金额数据
export function getRangeMaintainSumList(data) {
	return request({
		url: '/suf4-bi-service/maintenance/data/period/amountCount',
		method: 'post',
		data,
	})
}
// 事故数据-本日
export function getTodayAccidentInfo(data) {
	return request({
		url: '/suf4-bi-service/accident/data/day',
		method: 'post',
		data,
	})
}
// 事故数据-期间
export function getRangeAccidentInfo(data) {
	return request({
		url: '/suf4-bi-service/accident/data/period',
		method: 'post',
		data,
	})
}
// 事故数据-有责本日
export function getTodayAccidentList(data) {
	return request({
		url: '/suf4-bi-service/accident/data/responsible/day',
		method: 'post',
		data,
	})
}
// 事故数据-有责期间
export function getRangeAccidentList(data) {
	return request({
		url: '/suf4-bi-service/accident/data/responsible/period',
		method: 'post',
		data,
	})
}
