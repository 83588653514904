export const columns = [
	{
		prop: 'userName',
		label: '员工姓名',
		width: '125px',
	},
	{
		prop: 'userRole',
		label: '员工角色',
	},
	{
		prop: 'companyName',
		label: '所属公司',
	},
	{
		prop: 'dataDate',
		label: '数据月份',
		width: '125px',
	},
	{
		prop: 'driverCount',
		label: '当月负责司机数',
		width: '150px',
	},
	{
		prop: 'receivableRent',
		label: '当月应收租金(元)',
		width: '150px',
	},
	{
		prop: 'actualRent',
		label: '当月实收租金(元)',
		width: '150px',
	},
	{
		prop: 'rentCollectionRate',
		label: '当月租金收取率',
		width: '150px',
	},
	{
		prop: 'warningDriverCount',
		label: '当月违章预警司机数',
		width: '160px',
	},
	{
		prop: 'warningDriverHandled',
		label: '当月违章预警已处理',
		width: '160px',
	},
	{
		prop: 'warningDriverHandleRate',
		label: '当月违章预警处理率',
		width: '160px',
	},
	{
		prop: 'accidentDriverCount',
		label: '当月有责事故超2次司机',
		width: '180px',
	},
	{
		prop: 'accidentDriverCountRate',
		label: '当月有责事故超2次司机环比',
		width: '215px',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			attr: 'companyId',
			label: '所属公司',
			type: 'select',
			placeholder: '请选择所属公司',
			options: [],
		},
		{
			attr: 'userId',
			label: '员工姓名',
			type: 'select',
			placeholder: '请选择员工姓名',
			options: [],
		},
		{
			attr: 'dataDate',
			value: '',
			shortcuts: [],
			clearable: false,
			label: '数据时间',
			type: 'month',
		},
	],
}
